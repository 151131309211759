.service-heading {
    background-color: var(--theme-primary-background-color);
    padding: 90px 0px;

    .container {
        h1 {
            text-align: center;
            color: var(--theme-primary-font-color);
            font-size: var(--theme-heading-font-size-lg);
            font-weight: var(--theme-heading-font-weight);
            line-height: var(--theme-heading-line-height);
            cursor: var(--theme-cursor-default);
        }

        p {
            text-align: center;
            color: var(--theme-secondary-font-color);
            margin: 50px 0px;
            font-size: var(--theme-button-text-font-size);
            font-weight: var(--theme-button-text-font-weight-medium);
            line-height: var(--theme-button-text-font-line-height);
            cursor: var(--theme-cursor-default);
        }
    }
}


.main-grid {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .sub-grid {
        padding: 28px;
        background-color: var(--theme-primary-background-color1);
        border-radius: 12px;
        height: 220px;
        width: 210px;
        margin-top: 40px;
        transition: .2s ease-in-out;

        .position {
            position: relative;
            height: 50px;
            width: 50px;

            img {
                border-radius: 8px;
            }

            .group-image {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                height: 28px;
                width: 28px;

                img {
                    transform: translate(-50%, -50%);
                    border-radius: 0px;
                }
            }
        }

        .card-heading {
            h2 {
                font-size: var(--theme-text-font-size-md2);
                font-weight: var(--theme-heading-font-weight);
                line-height: var(--theme-text-line-height);
                color: var(--theme-primary-font-color);
                margin: 20px 0px;
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-text-font-size-sm2);
                font-weight: var(--theme-text-font-weight);
                line-height: var(--theme-text-card-line-height);
                color: var(--theme-secondary-font-color50);
                max-width: 200px;
                cursor: var(--theme-cursor-default);

            }
        }
    }
}

.sub-grid:hover {
    transform: translate(0, -9px);
    transition: .2s ease-in-out;
}






@media (max-width:768px) {
    .service-heading {
        .container {
            h1 {
                font-size: 38px;
            }
            p{
                font-size: 14px;
            }
        }
    }

    .main-grid {
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .sub-grid {
            background-color: var(--theme-primary-background-color1);
            border-radius: 12px;
            margin: 0 auto;
        }
    }
}

@media (max-width:576px) {
    .service-heading {
        .container {
            h1 {
                font-size: 38px;
            }
            p{
                font-size: 16px;
                padding: 0px 24px;
            }
        }
    }

    .main-grid {
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        .sub-grid {
            padding: 30px 160px 30px 30px;
            background-color: var(--theme-primary-background-color1);
            border-radius: 12px;
        }
    }
}