.hero-banner-main-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--theme-primary-background-color1);
    height: 100vh;

    .sub-grid-text {
        margin-left: 110px;
        padding: 200px 0px;

        h1 {
            font-size: var(--theme-heading-font-size-lg);
            font-weight: var(--theme-heading-font-weight);
            line-height: var(--theme-heading-line-height);
            color: var(--theme-primary-font-color);
            max-width: 506px;
            cursor: var(--theme-cursor-default);

            span {
                background: rgb(187,102,214);
                background: linear-gradient(119deg, rgba(187,102,214,1) 0%, rgba(88,63,187,1) 33%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: var(--theme-text-font-size-md);
            font-weight: var(--theme-text-font-weight);
            line-height: var(--theme-text-line-height);
            max-width: 500px;
            margin-top: 14px;
            color: var(--theme-secondary-font-color);
            letter-spacing: 0.4px;
            cursor: var(--theme-cursor-default);
        }

        button {
            padding: 14px 20px;
            font-size: var(--theme-button-text-font-size);
            font-weight: var(--theme-button-text-font-weight);
            margin-top: 50px;
            border-bottom: 1px solid #fff;
            display: flex;
            align-items: center;

            img {
                padding-left: 10px;
                height: 24px;
                width: 24px;
            }
        }
    }

    .sub-grid-image {
        padding: 120px 0px;
    }
}

@media (max-width:576px) {
        .hero-banner-main-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            height: auto;
            width: auto;
            .sub-grid-text{
                padding: 40px 0px;
                max-width: 400px;
                h1{
                    margin-top: 50px;
                    font-size: 38px;
                }
                p{
                    font-size: 16px;
                }
            }
        }
        .sub-grid-image{
            img{
                height: 400px;
                width: 550px;
            }
        }
    }


// @media (max-width:768px) {
//     .hero-banner-main-grid {
//         display: grid;
//         grid-template-columns: repeat(1, 1fr);
//         height: auto;
//         width: auto;

//         .sub-grid-text {
//             padding: 40px 0px;
//             max-width: 400px;

//             h1 {
//                 margin-top: 100px;
//                 font-size: 38px;
//             }
//         }
//     }

//     .sub-grid-image {
//         img {
//             height: 400px;
//             width: 600px;
//         }
//     }
// }