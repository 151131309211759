.main-background-color-about{
    background-color: var(--theme-primary-background-color);
    .about-card-grid{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 40px;
        padding: 100px 0px;
        .about-sub-card{
            background-color: var(--theme-primary-background-color1);
            padding: 30px;
            border-radius: 10px;
            transition: .3s ease-in-out;
            h2{
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                color: var(--theme-primary-font-color);
                margin: 20px 0px;
                cursor: var(--theme-cursor-default);
            }
            p{
                font-size: var(--theme-text-font-size-md);
                font-weight: var(--theme-text-font-weight);
                line-height: 27.9px;
                color: var(--theme-secondary-font-color);
                margin-bottom: 20px;
                cursor: var(--theme-cursor-default);
            }
        }
    }
    .border-bottom{
        border-bottom: 1px solid #4E4949;
    }
}
.about-sub-card:hover{
    transform: translateY(-3%);
    transition: .3s ease-in-out;
}

@media (max-width: 576px) {
    .main-background-color-about{
        .about-card-grid{
            grid-template-columns: repeat(1,1fr);
            gap: 45px;
            padding: 60px 30px;
            .about-sub-card{
                img{
                    height: 110px;
                    width: 110px;
                }
                h2{
                    font-size: 28px;
                }
                p{
                    font-size: 18px;
                }
            }
        }
    }
    
}