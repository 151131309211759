.blog-hero-back-ground {
    background-color: var(--theme-primary-background-color);

    .main-grid-image {
        display: grid;
        grid-template-columns: 740px 1fr;
        gap: 20px;
        padding: 90px 0px;

        .image-lg {
            h2 {
                color: #fff;
                font-size: var(--theme-blog-hero-heading-font-size);
                font-weight: var(--theme-blog-hero-heading-font-weight);
                line-height: var(--theme-blog-hero-heading-line-height);
                padding: 20px 0px;
                cursor: var(--theme-cursor-default);
            }

            p {
                color: #FFFFFFB2;
                font-size: var(--theme-blog-hero-text-font-size);
                font-weight: var(--theme-blog-hero-text-font-weight);
                line-height: var(--theme-blog-hero-text-line-height);
                max-width: 650px;
                cursor: var(--theme-cursor-default);
            }

            img {
                transition: .3s ease-in-out;
            }

            img:hover {
                transform: scale(0.9);
                transition: .3s ease-in-out;
            }

            button {
                padding: 12px 23px;
                border-radius: 6px;
                margin-top: 30px;
                font-weight: 400;
            }
        }

        .image-md {
            .text-flex {
                display: flex;
                gap: 20px;

                .image-card {
                    img {
                        margin-bottom: 20px;
                        transition: .3s ease-in-out;
                    }

                    img:hover {
                        transition: .3s ease-in-out;
                        transform: scale(1.1);
                    }
                }

                .text-card {
                    p {
                        color: #fff;
                        font-size: var(--theme-blog-hero-text-font-size);
                        font-weight: 500;
                        line-height: var(--theme-blog-hero-text-line-height);
                        cursor: var(--theme-cursor-default);
                    }

                    h3 {
                        color: var(--theme-header-menu-font-color);
                        font-size: var(--theme-blog-hero-text-font-size);
                        font-weight: 600;
                        line-height: 19px;
                        margin-top: 20px;
                        border-bottom: 1.6px solid #BB66D6;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .blog-hero-back-ground {
        .main-grid-image {
            padding: 60px 0px;
            display: grid;
            grid-template-columns: repeat(1,1fr);
            .image-lg{
                img{
                    width: 100%;
                }
                h2{
                    font-size: 24px;
                    padding: 20px 24px;
                }
                p{
                    padding: 0px 24px;
                    font-size: 14px;
                }
                button{
                    margin: 30px 24px;
                }
            }
            .image-md{
                .text-flex{
                    .image-card{
                        img{
                            padding-left: 24px;
                        }
                    }
                    .text-card{
                        p{
                            font-size: 14px;
                            max-width: 300px;
                        }
                        h3{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

}