.footer-background {
    background-color: var(--theme-primary-background-color);
    padding-top: 90px;

    .container {
        h1 {
            text-align: center;
            font-size: var(--theme-footer-heading-font-size);
            font-weight: var(--theme-footer-heading-font-weight);
            line-height: var(--theme-footer-heading-line-height);
            color: var(--theme-primary-font-color);
            cursor: var(--theme-cursor-default);
        }

        .footer-text {
            display: flex;
            justify-content: center;

            p {
                font-size: var(--theme-text-font-size-sm);
                font-weight: var(--theme-text-font-weight);
                line-height: 25px;
                color: #F6F6F6;
                max-width: 650px;
                text-align: center;
                margin-top: 20px;
                cursor: var(--theme-cursor-default);
            }
        }
    }

    .footer-main-grid {
        border-bottom: 1px solid #FFFFFF4D;
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        .sub-grid-info {
            .main-contact-info {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;

                .sub-contact {
                    padding-bottom: 40px;
                    p {
                        color: var(--theme-secondary-font-color50);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.36px;
                        margin-bottom: 20px;
                        cursor: var(--theme-cursor-pointer);
                    }
                }
            }
        }
    }
    .copy-right{
    p{
        color: #FFFFFF66;
        text-align: center;
        padding: 30px 0px;
        cursor: var(--theme-cursor-default);
    }
}
}