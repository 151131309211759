.container {
    .heading-text {
        margin-top: 70px;

        p {
            text-align: center;
            font-size: 22px;
            font-weight: 400px;
            line-height: 26.63px;
            color: #8D8D8D;
            cursor: var(--theme-cursor-default);
        }

        h1 {
            text-align: center;
            margin: 20px 0px;
            font-size: var(--theme-heading-font-size-lg2);
            font-weight: 700;
            line-height: var(--theme-text-finest-work-heading-line-height);
            cursor: var(--theme-cursor-default);
        }

        img {
            width: 100%;
        }
    }
    .text-paragraph{
        p{
            font-size: var(--theme-blog-hero-text-font-size);
            font-weight: var(--theme-blog-hero-text-font-weight);
            line-height: var(--theme-blog-hero-text-line-height);
            color: var(--theme-blog-details-text-color);
            margin-top: 30px;
            cursor: var(--theme-cursor-default);
        }
    }
}

@media (max-width: 576px) {
    .container{
        .heading-text{
            margin-top: 30px;
            p{
                font-size: 16px;
            }
            h1{
                font-size: 36px;
            }
            img{
                width: 100%;
            }
        }
        .text-paragraph{
            p{
                font-size: 14px;
                margin-top: 20px;
                padding: 0px 24px;
            }
        }
    }
    
}