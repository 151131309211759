button {
    background: rgb(187, 102, 214);
    background: linear-gradient(119deg, rgba(187, 102, 214, 1) 20%, rgba(88, 63, 187, 1) 89%);
    border: none;
    border-radius: 52px;
    color: #fff;
    cursor: pointer;
    font-size: var(--theme-button-text-font-size);
    font-weight: var(--theme-button-text-font-weight);
    line-height: var(--theme-button-text-font-line-height);
}