.background-color {
    padding: 100px 0px;
    background-color: var(--theme-primary-background-color);

    .container {

        h2 {
            text-align: center;
            color: var(--theme-primary-font-color);
            font-size: var(--theme-heading-font-size-lg);
            font-weight: var(--theme-heading-font-weight);
            line-height: var(--theme-heading-line-height);
            margin-bottom: 26px;
            cursor: var(--theme-cursor-default);
        }

        p {
            color: var(--theme-primary-font-color);
            text-align: center;
            cursor: var(--theme-cursor-default);
        }
    }

    .grid-card-main {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 70px;

        .sub-card {
            .image-flex{
            display: flex;
            justify-content: center;
            }
            h3{
                text-align: center;
                padding: 20px 0px;
                font-size: var(--theme-tech-heading-font-size);
                font-weight: var(--theme-heading-font-weight);
                line-height: var(--theme-tech-heading-line-height);
                color: var(--theme-primary-font-color);
                cursor: var(--theme-cursor-default);
            }
            p{
                font-size: var(--theme-text-font-size-sm2);
                font-weight: var(--theme-text-font-weight);
                line-height: var(--theme-tech-text-line-height);
                color: var(--theme-secondary-font-color50);
                cursor: var(--theme-cursor-default);
            }
        }
    }
}

@media (max-width:576px) {
    .background-color{
        .container{
            h2{
                font-size: 36px;
            }
            p{
                padding: 0px 24px;
            }
        }
        .grid-card-main{
            grid-template-columns: repeat(2,1fr);
            padding: 0px 24px;
            .sub-card{
                border-radius: 10px;
                padding: 18px 10px;
                background-color: var(--theme-primary-background-color1);
                .image-flex{
                    img{
                        height: 70px;
                        width: 70px;
                    }
                }
                h3{
                    font-size: 24px;
                }
            }
        }
    }
}