.text-para-image{
    margin-bottom: 120px;
    p{
        font-size: var(--theme-blog-hero-text-font-size);
        font-weight: var(--theme-blog-hero-text-font-weight);
        line-height: var(--theme-blog-hero-text-line-height);     
        color: var(--theme-blog-details-text-color);
        margin-top: 30px;
        cursor: var(--theme-cursor-default);
    }
    img{
        width: 100%;
        margin-top: 90px;
    }
}

@media (max-width: 576px) {
    .text-para-image{
        margin-bottom: 70px;
        p{
            font-size: 14px;
            margin-top: 20px;
            padding: 0px 24px;
        }
        img{
            margin-top: 40px;
        }
    }
    
}