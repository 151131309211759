.conversation-background {
    background-color: var(--theme-primary-background-color);
    padding: 100px 0px;
    

    h1 {
        color: var(--theme-primary-font-color);
        font-size: var(--theme-heading-font-size-lg2);
        font-weight: var(--theme-heading-font-weight);
        line-height: var(--theme-text-finest-work-heading-line-height);
        text-align: center;
        cursor: var(--theme-cursor-default);
    }

    .main-grid-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 100px;
        margin-top: 100px;

        .conver-text {
            h2 {
                color: var(--theme-primary-font-color);
                font-size: 32px;
                font-weight: 600;
                line-height: 38px;
                cursor: var(--theme-cursor-default);
                margin-bottom: 40px;
            }

            p {
                font-size: var(--theme-text-font-size-md);
                font-weight: var(--theme-text-font-weight);
                line-height: 27.9px;
                color: var(--theme-secondary-font-color);
                cursor: var(--theme-cursor-default);
                margin-bottom: 20px;
            }
        }

        .conver-form {
            font-size: 16px;

            .lable {
                font-size: var(--theme-header-menu-sub-heading-font-size);
                font-weight: var(--theme-header-menu-sub-heading-font-weight);
                line-height: var(--theme-header-menu-sub-heading-line-height);
                color: var(--theme-secondary-font-color);
                cursor: var(--theme-cursor-default);
            }

            .input-name {
                input {
                    font-size: var(--theme-header-menu-sub-heading-font-size);
                    font-weight: var(--theme-header-menu-sub-heading-font-weight);
                    line-height: var(--theme-header-menu-sub-heading-line-height);
                    color: var(--theme-secondary-font-color);
                    background-color: #FFFFFF1A;
                    border: none;
                    width: 100%;
                    padding: 16px 0px 16px 20px;
                    border-radius: 10px;
                    margin: 10px 0px 30px 0px;
                }
            }
            .input-name1 {
                input {
                    font-size: var(--theme-header-menu-sub-heading-font-size);
                    font-weight: var(--theme-header-menu-sub-heading-font-weight);
                    line-height: var(--theme-header-menu-sub-heading-line-height);
                    color: var(--theme-secondary-font-color);
                    background-color: #FFFFFF1A;
                    border: none;
                    width: 100%;
                    padding: 16px 0px 90px 20px;
                    border-radius: 10px;
                    margin: 10px 0px 30px 0px;
                }
            }
            button{
                padding: 12px 32px;
                border-radius: 6px;
                font-weight: var(--theme-button-text-font-weight-medium);
            }
        }
    }
    .border-bottom{
        border-bottom: 1px solid #4E4949;
        margin-top: 100px;
    }
}

@media (max-width: 576px) {
    .conversation-background{
        h1{
            font-size: 38px;
        }
        .main-grid-form {
            grid-template-columns: repeat(1,1fr);
            .conver-text{
                padding: 0px 24px;
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 18px;
                }
            }
            .conver-form{
                margin: 0px 30px;
                .lable{
                    margin: 0px 40px;
                }
                .input-name{
                    margin: 0px 40px;
                }
                .input-name1{
                    margin: 0px 40px;
                }
            }
            button{
                margin: 0 45px;
            }
        }
    }
}