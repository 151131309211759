.main-background {
    background-color: var(--theme-primary-background-color1);
    padding: 60px 0px;

    h1 {
        font-size: var(--theme-heading-font-size-lg);
        font-weight: var(--theme-heading-font-weight);
        line-height: var(--theme-heading-line-height);
        color: var(--theme-primary-font-color);
        text-align: center;
        cursor: var(--theme-cursor-default);
    }

    .main-flex {
        display: flex;
        gap: 50px;
        align-items: center;
        margin-top: 100px;

        .finest-image {
            img {
                transition: .3s ease-in-out;
            }
        }

        .text-work {
            h2 {
                font-size: var(--theme-heading-font-size-lg2);
                font-weight: var(--theme-heading-font-weight);
                line-height: var(--theme-text-finest-work-line-height);
                color: var(--theme-primary-font-color);
                margin-bottom: 20px;
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-text-font-size-md2);
                font-weight: var(--theme-text-font-weight);
                line-height: var(--theme-text-finest-work-text-line-height);
                color: var(--theme-secondary-font-color50);
                cursor: var(--theme-cursor-default);
            }
        }
    }

    .main-flex1 {
        display: flex;
        gap: 50px;
        align-items: center;
        margin-top: 100px;


        .finest-image {
            img {
                transition: .3s ease-in-out;
            }
        }

        .text-work {
            h2 {
                font-size: var(--theme-heading-font-size-lg2);
                font-weight: var(--theme-heading-font-weight);
                line-height: var(--theme-text-finest-work-line-height);
                color: var(--theme-primary-font-color);
                margin-bottom: 20px;
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-text-font-size-md2);
                font-weight: var(--theme-text-font-weight);
                line-height: var(--theme-text-finest-work-text-line-height);
                color: var(--theme-secondary-font-color50);
                cursor: var(--theme-cursor-default);
            }
        }
    }
}

.main-flex img:hover {
    transform: scale(1.1);
    transition: .3s ease-in-out;
}

.main-flex1 img:hover {
    transform: scale(1.1);
    transition: .3s ease-in-out;
}

@media (max-width:576px) {
    .main-background {
        .container {
            h1 {
                font-size: 36px;
            }
        }

        .main-flex {
            display: flex;
            flex-direction: column;

            .finest-image {
                img {
                    margin: 0 auto;
                }
            }

            .text-work {
                h2 {
                    font-size: 36px;
                    text-align: center;
                    margin: 20px 0px;
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    padding: 0px 40px;
                }
            }

            .finest-image {
                img {
                    margin: 0 auto;
                }
            }
        }

        .main-flex1 {
            display: flex;
            flex-direction: column;

            .text-work {
                display: flex;
                flex-direction: column;
                order: 2;

                h2 {
                    font-size: 36px;
                    text-align: center;
                    margin: 20px 0px;
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    padding: 0px 40px;
                }
            }
        }
    }
}