.main-background-color-product {
    background-color: var(--theme-primary-background-color);
    padding: 100px 0px;

    .main-part-flex {
        display: flex;
        gap: 80px;
        align-items: center;
        .sub-image-product{
            img{
                transition: .3s ease-in-out;
            }
            img:hover{
                transform: translateY(-3%);
                transition: .3s ease-in-out;
            }
        }

        .sub-text-product {
            h2 {
                font-size: var(--theme-footer-heading-font-size);
                font-weight: var(--theme-footer-heading-font-weight);
                line-height: var(--theme-footer-heading-line-height);
                color: var(--theme-primary-font-color);
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-text-font-size-md2);
                font-weight: var(--theme-text-font-weight);
                line-height: 25px;
                color: #ADADAD;
                margin-top: 30px;
                cursor: var(--theme-cursor-default);
            }
        }
    }
}

.main-background-color-product2 {
    background-color: var(--theme-primary-background-color1);
    padding: 100px 0px;

    .main-part-flex {
        display: flex;
        gap: 80px;
        align-items: center;
        
        .sub-image-product{
            img{
                transition: .3s ease-in-out;
            }
            img:hover{
                transform: translateY(-3%);
                transition: .3s ease-in-out;
            }
        }

        .sub-text-product {
            h2 {
                font-size: var(--theme-footer-heading-font-size);
                font-weight: var(--theme-footer-heading-font-weight);
                line-height: var(--theme-footer-heading-line-height);
                color: var(--theme-primary-font-color);
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-text-font-size-md2);
                font-weight: var(--theme-text-font-weight);
                line-height: 25px;
                color: #ADADAD;
                margin-top: 30px;
                cursor: var(--theme-cursor-default);
            }
        }
    }
}
.border-bottom{
    border-bottom: 1px solid #4E4949;
}

@media (max-width: 576px) {
    .main-background-color-product {
        .main-part-flex{
            gap: 40px;
            flex-direction: column;
            .sub-image-product{
                order: 1;
            }
            .sub-text-product{
                h2{
                    font-size: 36px;
                    text-align: center;
                }
                p{
                    font-size: 14px;
                    padding: 0 24px;
                }
            }
        }
    }
    .main-background-color-product2 {
        .main-part-flex{
            gap: 40px;
            flex-direction: column;
            .sub-text-product{
                h2{
                    font-size: 36px;
                    text-align: center;
                }
                p{
                    font-size: 14px;
                    padding: 0 24px;
                }
            }
        }
    }
}