.mobile-app-main{
    img{
        width: 100%;
        margin-top: 80px;
        border: 1px solid #000;
        border-radius: 20px;
    }
    p{
        font-size: var(--theme-blog-hero-text-font-size);
        font-weight: var(--theme-blog-hero-text-font-weight);
        line-height: var(--theme-blog-hero-text-line-height);
        color: var(--theme-blog-details-text-color);
        margin: 30px 0px;
        cursor: var(--theme-cursor-default);
    }
    .main-grid-mob-app{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 30px;
        margin-bottom: 100px;
        .sub-mob-app-image{
            img{
                border: none;
            }
            p{
                color: #4F4F4F99;
                cursor: var(--theme-cursor-default);
            }
            h4{
                font-size: var(--theme-text-font-size-md2);
                font-weight: 500;
                line-height: var(--theme-text-line-height);
                margin-bottom: 20px;
                cursor: var(--theme-cursor-default);
            }
            h3{
                color: var(--theme-header-menu-font-color);
                border-bottom: 2px solid #BB66D6;
                display: inline-block;
            }
        }
    }
    .text-para-two{
        margin-bottom: 100px;
    }
}

@media (max-width: 576px) {
    .mobile-app-main{
        img{
            margin-top: 50px;
        }
        p{
            padding: 0px 24px;
            font-size: 14px;
            margin: 18px 0px;
        }
        .main-grid-mob-app{
            gap: 10px;
            padding: 0px 24px;
        }
    }
    
}