.main-background-color{
    padding: 100px 0px;
    text-align: center;
    background-color: var(--theme-primary-background-color1);
    h2{
        font-size: var(--theme-heading-font-size-lg);
        font-weight: var(--theme-heading-font-weight);
        line-height: var(--theme-heading-line-height);
        color: var(--theme-primary-font-color);
        margin-bottom: 30px;
        cursor: var(--theme-cursor-default);
       
    }
    .project-text{
        display: flex;
        justify-content: center;
        p{
            color: var(--theme-secondary-font-color);
            max-width: 800px;
            cursor: var(--theme-cursor-default);
        }
    }
    button{
        height: 52px;
        width: 204px;
        margin-top: 50px;
        border-radius: 10px;
        font-size: 20px;
    }
}

@media (max-width:576px) {
    .main-background-color{ 
        h2{
            font-size: 36px;
        }
        p{
            font-size: 14px;
            padding: 0px 24px;
        }
    }
}