.black-background-bd {
    background-color: #000;
    margin: 90px 120px 90px 0px;
    height: fit-content;

    .container {
        .main-grid-blog-details {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 200px;

            .bd-text {
                max-width: 600px;
                padding: 90px 0px;

                h1 {
                    color: var(--theme-primary-font-color);
                    font-size: var(--theme-blog-hero-heading-font-size);
                    font-weight: var(--theme-blog-hero-heading-font-weight);
                    line-height: 38px;
                    cursor: var(--theme-cursor-default);
                }

                p {
                    font-size: var(--theme-header-menu-sub-heading-font-size);
                    font-weight: var(--theme-header-menu-sub-heading-font-weight);
                    line-height: 24px;
                    letter-spacing: 1px;
                    color: #F6F6F6;
                    margin-top: 20px;
                    cursor: var(--theme-cursor-default);
                }

                button {
                    margin-top: 30px;
                    padding: 12px 22px;
                    border-radius: 10px;
                    font-size: var(--theme-button-text-font-size);
                    font-weight: var(--theme-button-text-font-weight-medium);
                    line-height: 19.36px;
                }
            }

            .bd-image {
                img {
                    // height: 460px;
                    // width: 393px;
                    margin-top: -30px 0px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .black-background-bd {
        margin: 40px 80px 40px 0px;
        width: 100%;

        .container {
            .main-grid-blog-details {
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .bd-text {
                    max-width: 500px;
                    width: 100%;
                    padding: 30px 40px;

                    h1 {
                        font-size: 24px;
                    }

                    p {}
                }

                .bd-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 400px;
                        width: 100%;
                    }
                }
            }
        }
    }
}