.background-services {
    background-color: var(--theme-primary-background-color);
    padding: 40px 0px;


    .service-main-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-top: 50px;

        .service-sub-card {
            background-color: var(--theme-primary-background-color1);
            padding: 20px;
            border-radius: 8px;
            transition: .3s ease-in-out;

            h2 {
                font-size: var(--theme-service-card-font-size);
                font-weight: var(--theme-service-card-font-weight);
                line-height: var(--theme-service-card-line-height);
                color: var(--theme-primary-font-color);
                margin-top: 30px;
                cursor: var(--theme-cursor-default);
            }

            p {
                font-size: var(--theme-blog-hero-text-font-size);
                font-weight: var(--theme-blog-hero-text-font-weight);
                line-height: var(--theme-blog-hero-text-line-height);
                color: var(--theme-secondary-font-color);
                margin-top: 20px;
                cursor: var(--theme-cursor-default);
            }

            h3 {
                margin-top: 20px;
            }
        }
    }

    .border-bottom {
        border-bottom: 1px solid #4E4949;
        margin-top: 120px;
    }

}

.service-sub-card:hover {
    transform: translateY(-4%);
    transition: .3s ease-in-out;
}

@media (max-width: 576px) {
    .background-services{
        .service-main-grid{
            grid-template-columns: repeat(1,1fr);
            .service-sub-card{
                margin: 0px 24px;
                    h2{
                        font-size: 20px;
                    }
            }
        }
        .border-bottom {
            // border: none;
        }
    }
    
}