.main-heading {
    background-image: url("../../../assets/images/Asset 1 2.png");
    background-color: var(--theme-primary-background-color1);

    h1 {
        font-size: var(--theme-header-menu-heading-font-size);
        font-weight: var(--theme-header-menu-heading-font-weight);
        line-height: var(--theme-header-menu-heading-line-height);
        color: var(--theme-header-menu-font-color);
        text-align: center;
        padding-top: 50px;
        cursor: var(--theme-cursor-default);
    }

    .flex-main {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-bottom: 60px;
        .text-home {
            p {
                color: var(--theme-header-menu-sub-heading-home-color);
                font-size: var(--theme-header-menu-sub-heading-font-size);
                font-weight: var(--theme-header-menu-sub-heading-font-weight);
                line-height: var(--theme-header-menu-sub-heading-line-height);
                padding-right: 10px;
                border-right: 1px solid #fff;
                text-decoration: none;
            }
        }

        .text-blog {
            color: var(--theme-header-menu-sub-heading-sub-page-color);
            font-size: var(--theme-header-menu-sub-heading-font-size);
            font-weight: var(--theme-header-menu-sub-heading-font-weight);
            line-height: var(--theme-header-menu-sub-heading-line-height);
            cursor: var(--theme-cursor-default);
        }
    }
}