.header-grid {
    background-color: var(--theme-primary-background-color1);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px 0px;
    justify-content: center;
    align-items: center;

    .header-logo {
        padding: 0px 120px;

    }

    .header-menu {
        display: flex;

        a {
            color: var(--theme-primary-font-color);
            font-size: var(--theme-text-font-size-sm);
            font-weight: var(--theme-text-font-weight);
            line-height: 19.36px;
            list-style: none;
            display: inline-block;
            padding: 0px 20px;
            text-decoration: none;
            transition: .3s ease-in-out;

        }

        // a::after {
        //     content: "";
        //     width: 0%;
        //     height: 2px;
        //     background-color: #fff;
        //     display: none;
        //     transition: .3s ease-in-out;
        //     margin-top: 2px;
        // }

        a:hover{
            // width: 100%;
            // transition: .3s ease-in-out;
            // display: block;
            text-decoration: underline;
            color: var(--theme-header-menu-font-color);
            transition: .3s ease-in-out;
        }

        a.active {
            color: var(--theme-header-menu-font-color);
        }
    }

    .login-button {
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;

        button {
            height: 40px;
            width: 105px;
        }

        p {
            color: var(--theme-primary-font-color);
            font-size: 16px;
            font-weight: 600;
            line-height: 19.36px;
            text-decoration: none;
            cursor: var(--theme-cursor-pointer);
        }
    }
}

@media (max-width: 576px) {
    .header-grid{
        .header-menu{
            a{
                display: none;
            }
        }
    }
    
}