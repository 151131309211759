.blog-post-background{
    background-color: var(--theme-primary-background-color1);
    padding: 50px 0px 100px 0px;
    h1{
        font-size: var( --theme-blog-post-font-size);
        font-weight: var(--theme-blog-post-font-weight);
        line-height: var(--theme-blog-post-line-height);
        color: var(--theme-primary-font-color);
        margin-bottom: 50px;
        cursor: var(--theme-cursor-default);
    }
    .image-grid-card{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        .sub-card-image{
            p{
                font-size: var(--theme-header-menu-sub-heading-font-size);
                font-weight: var(--theme-header-menu-sub-heading-font-weight);
                line-height: var(--theme-header-menu-sub-heading-line-height);
                color: #FFFFFF99;
                margin: 20px 0px;
                cursor: var(--theme-cursor-default);
            }
            h4{
                font-size: var(--theme-text-font-size-md2);
                font-weight: 500;
                line-height: var(--theme-text-line-height);
                color: var(--theme-primary-font-color);
                cursor: var(--theme-cursor-default);
            }
            h3{
                color: var(--theme-header-menu-font-color);
                font-size: var(--theme-blog-hero-text-font-size);
                font-weight: 600;
                line-height: 19px;
                margin-top: 20px;
                border-bottom: 1.6px solid #BB66D6;
                display: inline-block;

            }
            img{
                transition: .3s ease-in-out;
            }
            img:hover{
                transition: .3s ease-in-out;
                transform: translateY(-3%);
            }
        }
    }
}

@media (max-width:576px) {
    .blog-post-background{
        padding: 50px 0px 60px 0px;
        h1{
            text-align: center;
            line-height: 30px;
        }
        .image-grid-card{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            gap: 30px;
            margin: 0 100px;
            .sub-card-image{
                p{
                    font-size: 14px;
                }
                h4{
                    font-size: 16px;
                }
                h3{
                    font-size: 14px;
                }
            }
        }
    }
    
}