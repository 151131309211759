:root {
    --theme-primary-background-color1: #1C1D31;
    --theme-primary-background-color: #040312;
    --theme-cursor-default: default;
    --theme-cursor-pointer: pointer;

    --theme-primary-font-color: #fff;
    --theme-secondary-font-color: #FFFFFFB2;
    --theme-secondary-font-color50: #FFFFFF80;

    --theme-heading-font-size-lg: 50px;
    --theme-heading-font-size-lg2: 40px;
    --theme-tech-heading-font-size: 24px;
    --theme-text-font-size-md: 20px;
    --theme-text-font-size-md2: 18px;
    --theme-text-font-size-sm: 16px;
    --theme-text-font-size-sm2: 12px;

    --theme-heading-font-weight: 600;
    --theme-text-font-weight: 400;

    --theme-heading-line-height: 60.51px;
    --theme-text-line-height: 26.3px;
    --theme-tech-heading-line-height: 29.05px;
    --theme-tech-text-line-height: 21.12px;
    --theme-text-card-line-height: 20.76px;
    --theme-text-finest-work-heading-line-height: 48.41px;
    --theme-text-finest-work-text-line-height: 31.68px;

    --theme-button-text-font-size: 16px;
    --theme-button-text-font-weight-medium: 400;
    --theme-button-text-font-weight: 600;
    --theme-button-text-font-line-height: 16.36px;

    --theme-footer-heading-font-size: 36px;
    --theme-footer-heading-font-weight: 600;
    --theme-footer-heading-line-height: 43.57px;

    --theme-header-menu-heading-font-size: 64px;
    --theme-header-menu-heading-font-weight: 700;
    --theme-header-menu-heading-line-height: 77.45px;
    --theme-header-menu-font-color: #BB66D6;

    --theme-header-menu-sub-heading-font-size: 14px;
    --theme-header-menu-sub-heading-font-weight: 400;
    --theme-header-menu-sub-heading-line-height: 16.94px;
    --theme-header-menu-sub-heading-home-color: #fff;
    --theme-header-menu-sub-heading-sub-page-color: #583FBB;

    --theme-blog-hero-heading-font-size: 26px;
    --theme-blog-hero-heading-font-weight: 700;
    --theme-blog-hero-heading-line-height: 31.47px;

    --theme-blog-hero-text-font-size: 16px;
    --theme-blog-hero-text-font-weight: 400;
    --theme-blog-hero-text-line-height: 25px;
    --theme-blog-details-text-color: #797979;

    --theme-blog-post-font-size: 34px;
    --theme-blog-post-font-weight: 700;
    --theme-blog-post-line-height: 41.15px;

    --theme-service-card-font-size: 22px;
    --theme-service-card-font-weight: 600;
    --theme-service-card-line-height: 26.63px;

}