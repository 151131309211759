.popular-reso-background {
    background-color: var(--theme-primary-background-color);
    padding: 100px 0px;

    h1 {
        font-size: var(--theme-blog-post-font-size);
        font-weight: var(--theme-blog-post-font-weight);
        line-height: var(--theme-blog-post-line-height);
        color: var(--theme-primary-font-color);
        cursor: var(--theme-cursor-default);
    }

    .popular-grid {
        display: grid;
        grid-template-columns: 1fr 600px;
        margin-top: 40px;

        .popular-sub-grid {
            img {
                transition: .3s ease-in-out;
            }

            img:hover {
                transform: scale(1.1);
                transition: .3s ease-in-out;
            }

            .popular-flex {
                display: flex;
                gap: 30px;
                margin-top: 20px;

                .popular-image {
                    img {
                        transition: .3s ease-in-out;
                    }

                    img:hover {
                        transform: translateY(-2%);
                        transition: .3s ease-in-out;
                    }
                }

                .popular-text {
                    p {
                        color: var(--theme-primary-font-color);
                        font-size: var(--theme-blog-hero-text-font-size);
                        font-weight: 500;
                        line-height: var(--theme-blog-hero-text-line-height);
                        max-width: 300px;
                        cursor: var(--theme-cursor-default);
                    }

                    h3 {
                        color: var(--theme-header-menu-font-color);
                        font-size: var(--theme-blog-hero-text-font-size);
                        font-weight: 600;
                        line-height: 19px;
                        margin-top: 20px;
                        border-bottom: 1.6px solid #BB66D6;
                        display: inline-block;
                    }
                }
            }
        }

        .popular-sub-grid-img {
            img {
                transition: .3s ease-in-out;

                &:hover {
                    transform: scale(96%);
                    transition: .3s ease-in-out;
                }
            }
        }
    }
}

@media (max-width:576px) {
    .popular-reso-background {
        padding: 60px 0px;

        h1 {
            text-align: center;
            font-size: 32px;
            margin-bottom: 16px;
        }

        .popular-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            margin: 0px 30px;

            .popular-sub-grid-img {
                order: 1;

                img {
                    width: 100%;
                }
            }

            .popular-sub-grid {
                .popular-flex {
                    .popular-text {
                        p {
                            font-size: 14px;
                        }
                        h3{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

}